.logo{
    width: 25px;
    height: 15px;
    object-fit: contain;
    object-position: bottom center;
    margin-right: 5px;
}
.anchor{
    width: 180px;
}
.logo_social{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc( 100% - 180px);
}
.ranger{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}
.a{
    margin: 0;
    padding: 0!important;
}
@media (max-width: 767px){
    .ranger{
        width: 100%;
    }
    .anchor{
        display: block;
        margin: 0 auto;
    }
    .logo_social{
        width: 50%;
        justify-content: center;
        position: relative;
        top:-4px;
    }
    .anchor{
        position: relative;
        top: 6px;
        width: 45%;
    }
}
@media (max-width: 500px){
    .anchor{
        top: 13px;
    }
}