.image{
    width: 100%;
    max-height: 90px;
    object-fit: contain;
    object-position: center;
    max-width: 75%;
    margin: 0 auto;
}
.col_slide{
    height: 242px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}