.texte_about_section{
    width: 100%;
    position: relative;
    padding: 100px 7vw;
}
.texte_about_section:after {
    background-color :#2d4a79 ;
    opacity: .84;
    mix-blend-mode: multiply;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
    content: '';
    position: absolute;
}
.ranger{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    color: #fff;
    position: relative;
    z-index: 4
}
.col{
    width: calc( 33.333% - 4vw );
}

.col h3{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.875rem;
    margin-top: 0;
 }
 .col p{
    font-size: 0.75rem;
    line-height: 1.375rem;
 }
 @media (max-width: 1023px){
    .col{
        width: calc(100% - 0vw);
    }
 }