.main{
    padding-top: 80px;
}
.texte_about h2{
    color: #fb6630;
    text-transform: uppercase;
    font-size:  2.625rem;
    font-weight:700;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 106px;
    text-align: center;
  }
  .texte_about{
    text-align: left;
    width: 100%;
    max-width: 1240px;
    display: block;
    margin: 0 auto;
    color: #676565;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
    padding: 0 30px;
    padding-bottom: 190px;
    }
    .texte_experience h2{
        color: #fb6630;
        text-transform: uppercase;
        font-size:  2.625rem;
        font-weight:700;
        font-family: 'Roboto Condensed', sans-serif;
        margin-top: 2.25em;
        margin-bottom: 80px;
        line-height: 1.2;
      }
      .texte_experience p{
        font-size: 1.4167rem;
        line-height: 3rem;
      }
      .texte_experience{
        text-align: center;
        width: 100%;
        max-width: 1240px;
        display: block;
        margin: 0 auto;
        color: #676565;
        font-size: 1.563rem;
        font-weight: 400;
        line-height: 2rem;
        padding: 0 30px;
        padding-bottom: 60px;
        }
.about_section{
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 175px 15vw;
}
.form{
    padding-top: 130px;
    padding-bottom: 60px;
}
.ranger{
    display: flex;
    flex-wrap: wrap;
}
.col_texte{
    width: 50%;
}
.col_form{
    width: 50%;
}
.container{
    width: 100%;
    max-width: 1240px;
    display: block;
    margin:  0 auto;
    padding: 0 30px;
}
.col_texte h4{
    color:#4d4c4c;  
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    margin: 0;
    font-weight: 900;
}
.col_texte h2{
    color:#ccc;  
    text-transform: none;
    font-size: 3rem;
    line-height: 1;
    margin-top: 80px;
    margin-bottom: 110px;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
}
.col_texte h2.active{
    color: #fb6630;
}
.col_texte p {
    font-size: .875rem;
    font-weight:  900;
}
.col_texte a{
    color:#07509b;
    text-decoration: none;
}
@media (max-width: 1023px){
    .col_texte{
        width: 100%;
        font-size: 1.25rem;
        line-height: 1.75;
    }
    .col_form{
        width: 100%;
    }
    .texte_experience{
        font-size: 1.25rem;
        line-height: 1.75;
    }
}