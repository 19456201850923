.main{
    padding-top: 80px;
}
.texte_about h2{
    color: #fb6630;
    text-transform: uppercase;
    font-size:  2.625rem;
    font-weight:700;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 106px;
    text-align: center;
}
    .texte_about{
    text-align: left;
    width: 100%;
    max-width: 1240px;
    display: block;
    margin: 0 auto;
    color: #676565;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
    padding: 0 30px;
    padding-bottom: 190px;
}
.texte h2{
    color: #fb6630;
    text-transform: none;
    font-size:  2rem;
    font-weight:700;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: left;
    margin-top: 1.5em;
    margin-bottom: .25em;
}
.texte p{
    margin-top: 0;
    margin-bottom: 1em;
}
.texte{
    text-align: left;
    width: 100%;
    max-width: 1240px;
    display: block;
    margin: 0 auto;
    color: #676565;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
    padding: 0 30px;
    padding-bottom: 110px;
    padding-top: 100px;
}
.texte_experience h2{
    color: #fb6630;
    text-transform: uppercase;
    font-size:  2.625rem;
    font-weight:700;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 2.25em;
    margin-bottom: 80px;
    line-height: 1.2;
  }
  .texte_experience p{
    font-size: 1.4167rem;
    line-height: 3rem;
  }
.texte_experience{
    text-align: center;
    width: 100%;
    max-width: 1240px;
    display: block;
    margin: 0 auto;
    color: #676565;
    font-size: 1.563rem;
    font-weight: 400;
    line-height: 2rem;
    padding: 0 30px;
    padding-bottom: 60px;
}