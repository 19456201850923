.img{
    width: 25%;
}
.img[classe=large]{
    width: 50%;
}
@media (max-width: 767px){
    .img{
        width: 100%;
    }
    .img[classe=large]{
        width: 100%;
    }
}