.main{
    padding-top: 80px;
}
.texte_about h2{
    color: #fb6630;
    text-transform: uppercase;
    font-size:  2.625rem;
    font-weight:700;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 106px;
    text-align: center;
    margin-bottom: 74px;
  }
  .texte_about{
    text-align: left;
    width: 100%;
    max-width: 1240px;
    display: block;
    margin: 0 auto;
    color: #676565;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
    padding: 0 30px;
    padding-bottom: 190px;
    }
    .texte_experience h2{
      color: #fb6630;
      text-transform: uppercase;
      font-size:  2.625rem;
      font-weight:700;
      font-family: 'Roboto Condensed', sans-serif;
      margin-top: 2.25em;
      margin-bottom: 80px;
      line-height: 1.2;
    }
    .texte_experience p{
      font-size: 1.4167rem;
      line-height: 3rem;
    }
      .texte_experience{
        text-align: center;
        width: 100%;
        max-width: 1240px;
        display: block;
        margin: 0 auto;
        color: #676565;
        font-size: 1.563rem;
        font-weight: 400;
        line-height: 2rem;
        padding: 0 30px;
        padding-bottom: 60px;
        }
.about_section{
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 175px 10vw;
}
@media (max-width: 1023px){
  .texte_experience{
    font-size: 1.25rem;
    line-height: 1.75;;
  }
}

