.ranger{
    display: flex;
    flex-wrap: wrap;
    width: calc( 100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
}
.container{
    width: 100%;
    max-width: 1240px;
    display: block;
    margin: 0 auto;
    padding: 0 30px;
}
