.container{
    max-width: 1240px;
    padding: 0 30px;
    width: 100%;
    display: block;
    margin: 0 auto;
}
.title{
    font-size: 1.5rem;
    font-weight: 700;
    color: #fa662b;
    text-transform: uppercase;
    line-height: 1.2;
    margin-top: 130px;
    font-family: 'Roboto Condensed', sans-serif;
}