

.first{
    font-weight: 700;
}
.menu_item{
    font-weight: 400;
    position: relative;
}
.menu_item:hover .absolute{
    display: block;
    padding-top: 20px;
}
.absolute{
    position: absolute;
    left: 0;
    display: none;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 10px;
}
.ranger_space_between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.sub_item{
    margin-left: 0!important;
}
.sub_item a{
    display: block;
    padding: 7px 15px;
}