.banner{
    
    height: 200px;
    width: 100%;
    background-size: cover;
    display: flex;  
    flex-direction: column;
    justify-content: center;
    background-color: #e0e0e0;;
    
}
.main {
    padding-top: 78px;
    padding-bottom: 0px;
  }
  .titre{
     text-align: center;
     margin-top: 100px;
  }
  .container{
    width: 100%;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    position: relative;
  }
  .container .div{
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../images/degrader.png');
    background-repeat: repeat-x;
    background-size: contain;
    height: 100px;
    z-index: 2;
    width: 100%;
    display: none;
  }
  .colonne, .texte{
    width: 50%;
  }
  .ranger{
    background-image: url('../../images/site-web-sur-mesure.jpeg');
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
  }
  .action_texte{
    text-align: center;
    width: 800px;
    padding: 20px;
    background-color: rgb(99, 99, 99);
    display: block;
    margin: 40px auto;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    border-radius: 6px;
  }
  .action_texte h2{
    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
  }
  .action_texte h2 strong{
    font-size: 60px;
    color: #8bc53f;
  }
  .action_texte li{
    color: #fff;
  }
  .bg{
    background-image: url('../../images/pattern.svg');
    background-repeat: repeat;
    background-size: 40px;
    padding: 10px 0 20px;
  }
  .texte h2{
    color: #fb6630;
    text-transform: uppercase;
    font-size:  2.625rem;
    font-weight:700;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 232px;
    margin-bottom: 85px;;
  }
  .texte{
    text-align: center;
    width: 100%;
    max-width: 1240px;
    display: block;
    margin: 0 auto;
    color: #676565;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 3rem;
    padding: 0 30px;
    padding-bottom: 60px;
    }
    .texte_experience h2{
      color: #fb6630;
      text-transform: uppercase;
      font-size:  2.625rem;
      font-weight:700;
      font-family: 'Roboto Condensed', sans-serif;
      margin-top: 2.25em;
      margin-bottom: 80px;
      line-height: 1.2;
    }
    .texte_experience p{
      font-size: 1.4167rem;
      line-height: 3rem;
    }
    .texte_experience{
      text-align: center;
      width: 100%;
      max-width: 1240px;
      display: block;
      margin: 0 auto;
      color: #676565;
      font-size: 1.875rem;
      font-weight: 400;
      line-height: 3rem;
      padding: 0 30px;
      padding-bottom: 60px;
      }
    .accordeon{
      background-size: 100% auto;
      background-position: top 23% center;
      position: relative;
    }
    .accordeon .ranger-accordeon{
      border-bottom: 1px solid #fff;
    }
    .accordeon .ranger-accordeon:last-child{ 
      border-bottom: none;;
    }
    .accordeon .ranger-accordeon h3{
      margin: 0;
    }
    .accordeon:before{
      content: '';
      position: absolute;
      background-color: #001f3c;
      opacity: .54;
      mix-blend-mode: darken;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  @media (max-width: 1023px){
    .texte{
      font-size: 1.25rem;
      line-height: 1.75;
    }
  }