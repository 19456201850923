.item{
    position: relative;
    width: 25%;
    height: auto;
    border: none;
    overflow: hidden;
}

.image{
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
    z-index: -1;
    bottom: 0;
    right: 0;
    transform: scale(1);
    transition: all 4s ease;
}
.anchor{
    width: 100%;
    height: auto;
    position: relative;
    text-decoration: none;
}
.anchor:hover .title h3{
    color:#fb6630;
}
.img{
    width: 100%;
    height: auto;
    position: relative;
    transform: scale(1);
    transition: all 2.5s ease;
}
.anchor:hover .img{
    transform: scale(1.3);
}
.content{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%,-50%);
}
.content h3{
    color: #fff;
    font-size: 30px;
    margin: 0;
    text-align: center;
}
.content p{
    color: #fff;
    font-size: 30px;
    margin: 0;
    text-align: center;
}
.title{
    background-image: linear-gradient(to right, #0e3464, #365b80);
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.title h3{
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0;
    transition: all .5s ease;
}
.tuile{
    display: flex;
    flex-direction: column;
}
@media (max-width: 1279px){
    .title h3{
        font-size: 1.5vw;
    }
}
@media (max-width: 1023px){
    .item{
        width: 50%;
    }
    .title{
        height: 100px;
    }
    .title h3{
        font-size: 3vw;
    }
}
@media (max-width: 600px){
    .item{
        width: 100%;
    }
    .title{
        height: 100px;
    }
    .title h3{
        font-size:5vw;
    }
}