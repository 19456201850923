.fixed{
    position: fixed;
    top: 400px;
    right: 0;
    z-index: 99999;
    display: flex;
   
}
.bouton{
    background-color: #fb6630;
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transform: translate(-30%,-100%) rotate(-90deg);
    transform-origin: top right;
    position: relative;
}
.form{
    width: 400px;
    height: 470px;
    position: relative;
    right: -140px;
    padding: 30px;
    top: -171px;
    display: none;
}
@media (max-width: 500px){
    .form{
        width: calc( 100vw - 80px);
    }
}
.form:before{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #305d84;
    mix-blend-mode: multiply;
    z-index: -1;
    opacity: .85;
}
.form input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #fff;
    margin-bottom: 30px;
    height: 30px;
    background-color: transparent;
    color:#fff;
}
.textarea{
    width: 100%;
    border: none;
    border-bottom: 1px solid #fff;
    margin-bottom: 30px;
    height: 100px;
    background-color: transparent;
    color: #fff;
    padding: 5px 0;
}
.form input:focus, .textarea:focus{
    outline: none;
}
.form  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
  }
  
  .form  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
  }
  
  .form  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
  }
.retour{
    color: red;
    display: block;
    font-size: 0.75rem;
    font-weight: 900;
}
