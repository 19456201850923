.col {
    width: 25%;
    padding: 0 18px;
    margin-bottom: 60px;
}
.info{
    width: 100%;
    margin-top: 22px ;
}
.info p{
    margin: 0;
    line-height: 1.25;
    font-family: 'Roboto Condensed', sans-serif;
}
.name{
    font-size: 0.938rem;
    color: #08509b;
    font-weight: 700;
}
.job{
    font-style: italic;
    color: #202020;
    font-size: .75rem;
}
.phone{
    color:#08509b;
    font-style: italic;
    font-size: .75rem;
    font-weight :400;
}
.linkedin{
    width: 10px;
}
.envelope{
    width: 10px;
    position: relative;
    top:2px;
}
.link{
    position: relative;
    text-align: right;
    top: -20px;
}
.img img{
    width: 100%;
}
@media (max-width: 1023px){
    .col {
        width: 50%;
    }
}
@media (max-width: 767px){
    .col {
        width: 100%;
    }
}