.bgimage{
    height: calc( 45vw );
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-shadow: inset 0 2px 8px rgb(0 0 0 / 20%);
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;
    background-image: url('../images/key.svg');
    background-size: cover;
    background-position: center;
    z-index: 1;;
}
.abs{
    text-align: left;
    color:#fff;
    position: relative;
    z-index: 1;
    width: calc( 50% -  calc( ( 100vw - 1180px ) / 2 ) ) ;
    margin-left: calc( ( 100vw - 1180px ) / 2 ) ;
}
.bgimage:after{
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    top:0;
    left:0;
    background-color: #305d84;
    mix-blend-mode: multiply;
}
.abs h2{
    color: #fff;
    font-size: 3.25rem;
    font-weight: 400;
    margin: 0;
}
.abs h3{
    font-size: 1.25rem;
    font-weight: 900;
    color: #fb6630;  
    text-transform: uppercase;;
}
.readmore{
    text-align: left;
    color:#fb6630;
    position: relative;
    z-index: 2;
    width: calc( 50% -  calc( ( 100vw - 1180px ) / 2 ) ) ;
    margin-left: calc( ( 100vw - 1180px ) / 2 ) ;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 0.875rem;
    margin-top: 1.5rem;
    z-index: 3;
    text-decoration: none;;
}
@media (max-width: 1279px){
    .abs{
        margin-left: 50px;
    }
    .readmore{
        margin-left: 50px;
    }
    .abs h2{
        font-size: 3.5vw;
    }
}
@media (max-width: 1023px){
    .bgimage{
        min-height: 75vw;
        height: 80vh;
    }
    .abs{
        width: 60%;
        margin-left: 20%;
    }
    .bgimage:after{
        width: 80%;
        left: 10%;
    }
    .readmore{
        margin-left: 20%;
    }
    .abs h2{
        font-size: 8.25vw;
    }
}