.ranger_accordeon{
    border-bottom: 1px solid #fff;
    position: relative;
    overflow: hidden;
}
.ranger_accordeon:last-child{
    border-bottom: none;
}


.ranger_accordeon .accordeon_container{
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 1240px;
    color: #fff;
    text-align: center;
    font-size: 1.375rem;
    line-height: 1.75rem;
    padding: 0 30px;
}
