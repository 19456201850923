.rdsrvices{
    background-image: radial-gradient(circle at 160%, #0e3464, #18588e, #0e3464);
    padding: 120px 0;
    color: #fff;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 400;
}
.container{
    max-width: 1240px;
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 0 30px;
}
.rdsrvices h2{
    color:#fe642a;
    text-transform: uppercase;
    font-size :1.5rem;
    line-height: 1.2;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
}
.rdsrvices ul{
    columns: 4;
    width: 100%;
    list-style: none;
    padding-left: 0;
    margin-top: 2em;
}
@media (max-width: 1023px){
    .rdsrvices ul{
        columns: 2;
    }
}
@media (max-width: 767px){
    .rdsrvices ul{
        columns: 1;
    }
}