.header {
    width: 100vw;
    background-color: #ffffff;
    position: fixed;
    z-index: 999999;
    padding-bottom: 10px;;
  }
  .container_header{
    max-width: 1240px;
    padding: 0 30px;
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    top: 0;
    width: 100%;
    z-index: 9;
    margin: 0 auto;
  }
  .logo {
    width: 200px;
  }
  
  .header ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
 a:hover{
  color:#fb6630;
 }
  
  .header a, .header span {
    text-decoration: none;
    font-size: .75rem;
    color: #4c4949;
    cursor: pointer;
    padding: 0 7px;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .header a:hover,
  .header a:active,
  .header a.active,
  .header span:hover,
  .header span:active,
  .header span.active  {
    color: #fb6630;
  }
  
  .badge {
    background-color: #cc2062;
    color: white;
    border-radius: 12px;
    padding: 0 1rem;
    margin-left: 0.5rem;
  }
  .ranger{
    width: 50%;
  }
  .ranger_space_between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.first{
  font-weight: 700;
}
.menu{
  font-weight: 400;
  
}
@media (max-width: 767px){
  .container_header{
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 13px;
    align-items: center;
  }
  .ranger{
    width: 50%;
    justify-content: flex-end;
    position: relative;
    top: 5px;
    
  }
  .ranger_space_between{
    justify-content: center;
  }
}
@media (max-width: 767px) and (min-width: 501px){
  .ranger{
    position: relative;
    top: -15px;
  }
}