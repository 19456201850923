.bg{
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    height: 376px;
}
.bg:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 2;
    background-color: #2d4a79;
    opacity: .82;
    mix-blend-mode: multiply;
}
.titre{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}
.titre h1{
    font-size: 3.25rem;
    font-weight: 400;
    color: #fff;
    position: relative;
    z-index: 3;
}
.container{
    width: 100%;
    max-width: 1240px;
    padding: 0 30px;
    display: block;
    margin: 0 auto;
}
@media (max-width: 1023px){
    .bg:after {
        width: 80%;
        left: 10%;
    }
    .titre{
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
}