.col{
    width: 33.333%;
    padding: 0 8px;
    margin-bottom: 16px;
}
.col_video{
    width: 33.333%;
    
}
.img{
    width: 100%;
}
.div{
    width:100%;
    position: relative;
    top: 0;
    left: 0;
    height: 0;
    padding-bottom: 63.6%;
    position: relative;
}
.div iframe{
    width:100%!important;
    height: 100%!important;
    position: absolute;
    top: 0;
    left: 0;
}
.col p{
    color: #505a68;
    font-size: .75rem;
    font-weight: 500;
    margin: 0.5em 0 0;
}
@media (max-width: 1023px){
    .col, .col_video{
        width: 100%;
    }   
}