.container{
    display: block;
    margin: 0 auto;
    max-width: 1240px;
    width: 100%;
    padding: 0 30px;
}
.ranger{
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
}
.title, .sidebar{
    width: 230px;
}
.sidebar{
    border-top: 1px solid #e1e1e1;
    color:#646363;   
    font-size: .75rem;
    line-height: 1.25rem;
}
.sidebar hr{
    border-top: 1px solid #e1e1e1;
}
.sidebar p strong{
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}
.share{
    text-transform: uppercase;
}
.sidebar p{
    margin: 1.5em 0;;
}
.title h2{
    color: #fa662c;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
}
.job_description, .additionnal_info{
    width: calc( 100% - 230px);
    color: #555555;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding-left: 40px;
}
.job_description{
    padding-bottom: 20px;;
}
.job_description p:first-of-type, .additionnal_info p:first-of-type{
    margin-top: 0;
}
.ranger_info{
    display: flex;
    flex-wrap: wrap;
}
.additionnal_info h3{
    text-transform: uppercase;
    margin: .25em 0;
    font-size: 1rem;
    font-weight: 700;
    color: #555555;
}
.additionnal_info h3:first-of-type{
    margin-top: 0;
}
.additionnal_info ul{
    margin-top: 0;
    margin-bottom: 2em;
}
.additionnal_info ul li{
    list-style: none;
    position: relative;
}
.additionnal_info ul li:after{
    position: absolute;
    left: -15px;
    content: '–';
    top: 0px;
}
.icone{
    width: 23px;
    height: 16px;
    object-fit: contain;
    object-position: bottom center;
}
.ranger_reseaux{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ranger_reseaux img{
    margin-left: 10px;
}
.share{
    font-size: 0.75rem;
    font-weight: 900;
}