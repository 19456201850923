.ranger{
    display: flex;
    flex-wrap: wrap;
}
.col{
    width: 50%;
    background-image: radial-gradient(circle at -120%, #0e3464, #0e3464, #4f7492);
    color: #fff;
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.25rem;
    line-height: 2rem;
}
.col h3{
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 0;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
}
.col_bg{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
    min-height: 433px;
}
.col_bg img{
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
   opacity: 0;
   transition: all .5s ease;;
}
.col_bg:hover img{
    opacity: 1;
}
.ranger:nth-child(odd) .col{
    order:1;
    padding-left: calc( (100vw - 1240px) / 2 + 30px );
    padding-right: 75px;
}

.ranger:nth-child(odd) .col_bg{
    order: 2;
}
.ranger:nth-child(even) .col{
    order: 2;
    padding-right: calc( (100vw - 1240px) / 2 + 30px );
    padding-left: 75px;
}
.ranger:nth-child(even) .col_bg{
    order: 1;
}
@media (max-width: 1079px){
    .ranger:nth-child(odd) .col{
        padding-left: 30px;
        padding-right: 30px;
    }
    .ranger:nth-child(even) .col{
        padding-right: 30px;
        padding-left: 30px;
    }
}
@media (max-width: 1023px){
    .col,.col_bg{
        width: 100%;
    }
    .ranger:nth-child(odd) .col_bg{
        order: 1;
    }
    .ranger:nth-child(even) .col_bg{
        order: 2;
    }
}