.titre{
    color:#4d4c4c;  
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    margin: 0 0 75px;
    font-weight: 900;
}
.ranger{
    display: flex;
    flex-wrap: wrap;
    width: calc( 100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
}
div.col{
    width: 50%;
    padding: 0 10px;
    margin-bottom:  30px;
}
div.full{
    width: 100%;
    padding: 0 10px;
}
.bg{
    background-color: #f8f8f8;
    padding: 0 20px 40px;
    position: relative;
}
.input, .textarea, .label{
    border: none;
    background-color:  #fff;
    width: 100%;
    padding: 10px 0;
    font-size: 0.875rem;
    line-height: 1.2;
    color:#666666; 
    height: 50px; 
    margin-top: 40px;
    padding: 0 10px;
    display: block;
}
.label{
    display: flex;
    align-items: center;
}
.col_sidebar{
    width: 250px;
    padding: 0 10px;
}
.col_message{
    width: calc( 100% - 250px );
    padding: 0 10px;;
}
.textarea{
    height: 150px;
    padding: 10px 10px;
}
.input:focus, .textarea:focus{
    outline: none;
    border-color: #000;;
}
.button{
    position: absolute;
    bottom: 40px;
    left: 20px;
    background-color: #fa662c;
    padding: 10px 40px;
    color: #fff;
    font-size: 0.813rem;
    text-transform: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    max-width: 230px;
}
.none, .hidden{
    display: none;
}
.retour{
    color: red;
    display: block;
    font-size: 0.75rem;
    font-weight: 900;
}
.filename{
    color: #ccc;
    display: block;
    font-size: 0.75rem;
    font-weight: 900;
}
