.footer{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0 70px;
}
.img{
    width: 200px;
    position: relative;
    top: 10px;
}
.ranger{
    display: flex;
    flex-wrap: wrap;
    width: calc( 100% + 0);
}
.footer p, .footer a{
    color: #4d4c4c;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.125rem;
    text-decoration: none;
}
.footer p:first-of-type{
    margin-top: 0;
}
.footer p strong{
    font-size: 0.875rem;
    font-weight: 900;
    color: #08509b;
    text-transform: uppercase;
}
.colonne{
    width: 50%;
    display: flex;
}
.copy{
    text-align: left;
    margin: 0;
    background-image: radial-gradient(circle at 100%, #0e3464, #18588e, #0e3464);
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    font-size: .75rem;
}
.container{
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
}
.colonne_1{
    width: calc(50% - 180px);
}
.colonne_2{
    width: calc(180px);
    position: relative;
}
.colonne_2:after{
    content: '';
    transform: translateY(-100%);
    position: absolute;
    top: -23px;
    width: 16px;
    height: 23px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../images/map-marker-alt.svg');
}
.colonne_3{
    width: calc(180px);
}
.colonne_4{
    width: calc(50% - 180px - 125px );
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.colonne_4 ul li a{
    text-decoration: none;
    color: #4d4c4c;
    min-width: 100px;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.125rem;
    display: block;
}
.colonne_4 ul li{
    min-width: 100px;
    display: block;
}
.colonne_5{
    width: calc( 125px);
}
.inner{
    max-width: 280px;
    display: flex;
}
.container{
    max-width: 1240px;
    width: 100%;
    padding: 0 30px;
}
.social{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 68px;
    float: right;
}
.circle{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
    cursor: pointer;
}
.circle img {
    width: 50%;
    height: 50%;
    object-fit: contain;
    object-position: center;
    transition: all .5s ease;;
}
.hover{
    display: none;
}
.circle_orange img{
    width: 50%;
    height: 50%;
    object-fit: contain;
    object-position: center;
}
.circle_orange{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fb6630;
}
.circle:hover{
    background-color: #fb6630;
}
.circle:hover .normal{
    display: none;
}
.circle:hover .hover{
    display: block;
}
@media (max-width: 1023px){
    .colonne_1{
        width: 50%;
    }
    .colonne_2{
        width: 50%;
    }
    .colonne_3{
        width: 50%;
        margin-top: 40px;
    }
    .colonne_4{
        width: 50%;
        align-items: flex-start;
        margin-top: 40px;
    }
    .colonne_5{
        width: 100%;
    }
}
@media (max-width: 767px){
    .colonne_1{
        width: 100%;
        margin-top: 40px;
    }
    .colonne_2{
        width: 100%;
        margin-top: 40px;
    }
    .colonne_3{
        width: 100%;
        margin-top: 40px;
    }
    .colonne_4{
        width: 100%;
        margin-top: 40px;
    }
    .colonne_5{
        margin-top: 40px;
        align-items: flex-start;;
    }
    .social{
        float: left;
    }
}