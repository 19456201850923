.ranger{
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e1e1e1;
    align-items: center;
    padding: 30px 0;
}
.ranger:last-of-type{
    border-bottom: 1px solid #e1e1e1;
}
.title_col{
    width: 34%;
}
.titre{
    margin: 0;
    text-transform: uppercase;
    color: #676565;
    font-size: 1rem;
    font-weight: 900;
}
.date{
    margin: 0;
    color: #fa662c;
    font-size: .75rem;
    font-weight: 500;
    line-height: 1rem;;
}
.location_col{
    width: 22%;
}
.location{
    margin: 0;
    text-transform: uppercase;
    color: #676565;
    font-size: .875rem;
    font-weight: 400;
    margin-top: 1rem;
}
.location strong{
    font-weight: 900;
}
.time_col{
    width: 22%;
}
.link{
    display: flex;
    justify-content: flex-end;
    width: 22%;
}
.time{
    margin: 0;
    text-transform: uppercase;
    color: #676565;
    font-size: .875rem;
    font-weight: 900;
    margin-top: 1rem;
}
.button{
    color: #fff;
    background-color: #61646c;
    padding: 0 30px;
    height: 28px;
    font-weight: 900;
    font-size: .75rem;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    transition: all .5s ease;
}
.button:hover {
    background-color: #fa662c;
}
@media (max-width: 1023px){
    .title_col{
        width: 100%;
    }
    .location_col{
        width: 100%;
    }
    .time_col{
        width: 100%;
    }
    .button{
        margin-top: 40px;
    }
    .link{
        width: 100%;
        justify-content: flex-start;
    }
}