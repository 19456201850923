.ranger{
    display: flex;
    flex-wrap: wrap;
}
.col{
    width: 25%;
}
.col img{ 
    width: 100%;
}
@media (max-width: 1023px){
    .col{
        width: 50%;
    }
}
@media (max-width: 767px){
    .col{
        width: 100%;
    }
}