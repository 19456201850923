.team{
    padding-top: 110px ;
    padding-bottom: 0px ;
    width: 100%;
}
.container{
    max-width: 1240px;
    padding: 0 30px;
    width: 100%;
    display: block;
    margin: 0 auto;
}
.intro{
    text-align: center;
}
.intro h2{
    color: #fb6630;
    text-transform: uppercase;
    font-size: 2.625rem;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    margin-bottom: 0;
}
.intro p{
    color: #676565;
    display: block;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
}
.teams{
    display: flex;
    flex-wrap: wrap;
    width: calc( 100% + 36px);
    margin-left: -18px;
    margin-right: -18px;
    margin-top: 115px;
}