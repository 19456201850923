.titre{
    color:#4d4c4c;  
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    margin: 0 0 75px;
    font-weight: 900;
}
.ranger{
    display: flex;
    flex-wrap: wrap;
    width: calc( 100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
}
div.col{
    width: 50%;
    padding: 0 10px;
    margin-bottom:  30px;
}
div.full{
    width: 100%;
    padding: 0 10px;
}
.input, .textarea{
    border: none;
    border-bottom: 1px solid #999999;
    width: 100%;
    padding: 10px 0;
    font-size: 0.875rem;
    line-height: 1.2;
    color:#000000; 
    height: 50px;  
}
.textarea{
    height: 150px;
    padding: 10px 0;
}
.input:focus, .textarea:focus{
    outline: none;
    border-color: #000;;
}
.button{
    margin-top: 70px;
}
.hidden{
    display: none;
}
.retour{
    color: red;
    display: block;
    font-size: 0.75rem;
    font-weight: 900;
}